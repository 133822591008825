// src/components/Header.js

import React from 'react';

const Header = () => {
  const handleBurgerClick = () => {
    // Placeholder for burger menu functionality
    console.log("Burger menu clicked!");
  };

  return (
    <header className="flex justify-between items-center p-4 bg-cyan-800 text-white">
      {/* Logo on the left */}
      <a href="/" className="text-xl font-bold">
        <img src="https://imgdb.net/storage/uploads/edb7b542104a1471ead160878e082735ac2693f597a13f256968561fb25c4d7b.ico" alt="Logo" className="h-8" /> {/* Replace with your logo URL */}
      </a>

      {/* Burger Menu on the right */}
      <button onClick={handleBurgerClick} className="md:hidden focus:outline-none">
        <div className="space-y-1">
          <div className="w-8 h-1 bg-white"></div>
          <div className="w-8 h-1 bg-white"></div>
          <div className="w-8 h-1 bg-white"></div>
        </div>
      </button>
    </header>
  );
};

export default Header;
