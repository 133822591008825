import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Finish = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Show a toast message when the component mounts
    toast.info('Proses sedang berlangsung. Silakan tunggu hasil verifikasi.', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Header Image via URL */}
        <div className="mb-6 text-center">
          <a href="https://example.com" target="_blank" rel="noopener noreferrer">
            <img
              src="https://imgdb.net/storage/uploads/6da2fe0b3c21b4f27e2402eaf9d86565a0fccffdc67fa96af0c09d13cee422cd.png" // Replace with your image URL
              alt="Header"
              className="h-auto w-80 rounded-2xl object-cover mx-auto" // Make the image round and larger
            />
          </a>
        </div>

        {/* Title and Subtitle */}
        <h2 className="text-2xl font-bold text-white mb-2">Status Sedang DiProses</h2>
        <p className="text-gray-400 mb-4">Berhasil di proses, silahkan tunggu.</p>

        {/* Processing GIF */}
        <img
          src="https://v2.cekloker.my.id/static/media/processing.18cf56f61956bd961bf9.gif"
          alt="Processing"
          className="w-32 h-auto mx-auto mb-4" // Adjust size as needed
        />

        {/* Additional Message */}
        <p className="text-gray-300 mb-6">
          Harap menunggu proses verifikasi dalam waktu 1x24 jam untuk memeriksa kelayakan, atau coba gunakan nomor telepon lain.
        </p>

        {/* Back Button */}
        <button
          onClick={() => navigate('/')} // Navigate back to home
          className="w-full bg-cyan-500 text-white font-semibold py-2 rounded-lg hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500"
        >
          &larr; Kembali
        </button>
      </div>
      <ToastContainer /> {/* Include the ToastContainer for Toastr */}
    </div>
  );
};

export default Finish;
