import React, { useState } from 'react';
import { FaTelegramPlane } from 'react-icons/fa'; // Telegram icon from react-icons
import { useNavigate } from 'react-router-dom'; // For navigation
import headerImage from '../assets/header.png'; // Correctly import your image

const BantuanSosialForm = () => {
  const [namaLengkap, setNamaLengkap] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [jenisKelamin, setJenisKelamin] = useState('');
  const [setuju, setSetuju] = useState(false);
  
  const navigate = useNavigate(); // Using hook for navigation

  const handleDateChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedDate = '';

    if (input.length >= 1) {
      formattedDate += input.substring(0, 2); // Add day
    }
    if (input.length >= 3) {
      formattedDate += '-' + input.substring(2, 4); // Add month
    }
    if (input.length >= 5) {
      formattedDate += '-' + input.substring(4, 8); // Add year
    }

    setTanggalLahir(formattedDate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate date format and age is at least 18 years
    const [day, month, year] = tanggalLahir.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const isOldEnough = age >= 18;

    if (!isOldEnough || isNaN(birthDate)) {
      alert('Anda harus berusia minimal 18 tahun dan tanggal lahir harus valid.');
      return;
    }

    if (setuju) {
      // Navigate to telegram.js page
      navigate('/telegram');
    } else {
      alert('Anda harus setuju bahwa Anda berusia minimal 18 tahun.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#212121]">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        {/* Header Image */}
        <div className="flex justify-center mb-6">
          <img 
            src={headerImage}
            alt="Header"
            className="h-auto w-80 rounded-2xl object-cover mx-auto"
          />
        </div>

        <h2 className="text-3xl font-bold mb-6 text-center text-[#212121]">Formulir Pendaftaran</h2>
        <p className="text-gray-600 text-sm text-center mb-4">Silakan isi formulir di bawah ini untuk mendaftar.</p>
        
        <form onSubmit={handleSubmit}>
          {/* Nama Lengkap */}
          <fieldset className="mb-4">
            <legend className="text-gray-700 text-lg font-bold mb-2">Informasi Pribadi</legend>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="namaLengkap">
                Nama Lengkap (Sesuai E-KTP)
              </label>
              <input
                id="namaLengkap"
                type="text"
                value={namaLengkap}
                onChange={(e) => setNamaLengkap(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
                required
              />
            </div>

            {/* Tanggal Lahir */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tanggalLahir">
                Tanggal Lahir (Minimal umur 18 tahun)
              </label>
              <input
                id="tanggalLahir"
                type="text"
                value={tanggalLahir}
                onChange={handleDateChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
                placeholder="DD-MM-YYYY" // Suggest format for user input
                maxLength="10" // Limit input to 10 characters
                required
              />
              <p className="text-xs text-gray-500">Format: DD-MM-YYYY</p> {/* Helper text for date format */}
            </div>

            {/* Jenis Kelamin */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="jenisKelamin">
                Jenis Kelamin
              </label>
              <select
                id="jenisKelamin"
                value={jenisKelamin}
                onChange={(e) => setJenisKelamin(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
                required
              >
                <option value="">Pilih Jenis Kelamin</option>
                <option value="Pria">Pria</option>
                <option value="Wanita">Wanita</option>
                <option value="Lainnya">Lainnya</option>
              </select>
            </div>
          </fieldset>

          {/* Checkbox for Age Agreement */}
          <div className="mb-4">
            <label className="flex items-center text-sm font-bold text-gray-700">
              <input
                type="checkbox"
                checked={setuju}
                onChange={() => setSetuju(!setuju)}
                className="mr-2 leading-tight"
              />
              Saya Setuju dengan semua syarat & ketentuan
            </label>
          </div>

          {/* Submit Button with Telegram Icon */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full flex justify-center items-center px-4 py-2 bg-cyan-500 text-white font-semibold rounded-lg shadow-md hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-400"
            >
              <FaTelegramPlane className="mr-2" />
              Daftar Dengan Telegram
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BantuanSosialForm;
