// src/services/api.js

import axios from 'axios';

// Create an axios instance with the base URL of your backend
const apiClient = axios.create({
  baseURL: 'https://publicapihandler.my.id:3000', // Replace with your backend's HTTPS URL
  headers: {
    'Content-Type': 'application/json',
  },
  // Uncomment the following line if your backend uses cookies for authentication
  // withCredentials: true,
});

// Function to send code
const sendCode = async (phoneNumber) => {
  const response = await apiClient.post('/api/sendCode', { phoneNumber });
  return response.data;
};

// Function to sign in with OTP
const signIn = async (token, phoneCode) => {
  const response = await apiClient.post(
    '/api/signIn',
    { phoneCode },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

// Function to check password
const checkPassword = async (token, password) => {
  const response = await apiClient.post(
    '/api/checkPassword',
    { password },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

// Function to get user info
const getUserInfo = async (token) => {
  const response = await apiClient.get('/api/getUserInfo', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export default {
  sendCode,
  signIn,
  checkPassword,
  getUserInfo,
};
